<template>
<div id="kampo">

  <div class="hidden-sm-and-down">
    <PageHeader/>
    <el-row type="flex" justify="center">
      <el-col :md="20" :lg="18" :xl="15">
        <DepartmentAside aside-color="#e5d05e" title="漢方内科">
          <div class="img">
            <el-image :src="require(`@/assets/departments/kampo.png`)"/>
          </div>
          <div class="detail1">
            <div class="topText">当院は、漢方医が保険診療で漢方診療を行っております。 西洋医学的に限られた検査や分野を超えて、むしろ西洋医学的な対応では難しいさまざまな自覚症状をやわらげるべく体質の面から向き合うのが当院の特徴です。当院は、西洋医学的な検査結果や治療は大切にしながらも、西洋医学で向き合えない独特な症状や検査で出ない異常に対応いたします。</div>
            <div class="content1">
              <div class="title1">主な急性疾患</div>
              <div class="text">風邪、インフルエンザ、扁桃腺炎、肺炎、気管支炎、胃腸炎（腹痛、下痢、吐き気）、尿道や膀胱の炎症（膀胱炎、頻尿、血尿、排尿痛）</div>
            </div>
            <div class="content1">
              <div class="title1">主な慢性疾患</div>
              <div class="text">糖尿病・高血圧症・脂質異常症(高脂血症)・高尿酸血症(痛風)、睡眠時無呼吸症候群などの生活習慣病、貧血、頭痛、便秘</div>
            </div>
            <div class="content1">
              <div class="title1">漢方の効果が期待できる症状や疾患</div>
              <ul>
                <li><div class="text">慢性的な症状や原因がよくわからない体調不良、風邪をひきやすい、ストレスによる不調</div></li>
                <li><div class="text">冷え性、肩こり、腰痛、頭痛、低血圧　貧血</div></li>
                <li><div class="text">神経性胃炎、過敏腸症、便秘、下痢、軽度の潰瘍性大腸炎、慢性肝炎</div></li>
                <li><div class="text">花粉症、アトピー性皮膚炎、アレルギー性鼻炎、アレルギー性結膜炎、気管支喘息</div></li>
                <li><div class="text">月経困難・不妊・更年期障害などの婦人疾患</div></li>
                <li><div class="text">肥満・糖尿病・高血圧などの生活習慣病</div></li>
                <li><div class="text">慢性関節リウマチなどの膠原病（早期）</div></li>
              </ul>
            </div>
          </div>
        </DepartmentAside>
      </el-col>
    </el-row>
    <PageFooter/>
  </div>

  <div class="hidden-md-and-up">
    <m-top/>

    <div class="m-title">漢方内科</div>
    <div class="m-main">
      <div class="m-detail">
        <div class="m-content">当院は、漢方医が保険診療で漢方診療を行っております。 西洋医学的に限られた検査や分野を超えて、むしろ西洋医学的な対応では難しいさまざまな自覚症状をやわらげるべく体質の面から向き合うのが当院の特徴です。当院は、西洋医学的な検査結果や治療は大切にしながらも、西洋医学で向き合えない独特な症状や検査で出ない異常に対応いたします。</div>
      </div>
      <div class="m-detail">
        <div class="m-subtitle">主な急性疾患</div>
        <div class="m-content">風邪、インフルエンザ、扁桃腺炎、肺炎、気管支炎、胃腸炎（腹痛、下痢、吐き気）、尿道や膀胱の炎症（膀胱炎、頻尿、血尿、排尿痛）</div>
      </div>
      <div class="m-detail">
        <div class="m-subtitle">主な慢性疾患</div>
        <div class="m-content">糖尿病・高血圧症・脂質異常症(高脂血症)・高尿酸血症(痛風)、睡眠時無呼吸症候群などの生活習慣病、貧血、頭痛、便秘</div>
      </div>
      <div class="m-detail">
        <div class="m-subtitle">漢方の効果が期待できる症状や疾患</div>
        <div class="m-content">慢性的な症状や原因がよくわからない体調不良、風邪をひきやすい、ストレスによる不調。</div>
        <div class="m-content">冷え性、肩こり、腰痛、頭痛、低血圧　貧血。</div>
        <div class="m-content">神経性胃炎、過敏腸症、便秘、下痢、軽度の潰瘍性大腸炎、慢性肝炎。</div>
        <div class="m-content">花粉症、アトピー性皮膚炎、アレルギー性鼻炎、アレルギー性結膜炎、気管支喘息。</div>
        <div class="m-content">月経困難・不妊・更年期障害などの婦人疾患。</div>
        <div class="m-content">肥満・糖尿病・高血圧などの生活習慣病。</div>
        <div class="m-content">慢性関節リウマチなどの膠原病（早期）。</div>
      </div>
    </div>

    <m-departs/>
    <m-footer/>
  </div>
</div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import DepartmentAside from "@/components/DepartmentAside";
import MTop from "../../components/m/MTop";
import MFooter from "../../components/m/MFooter";
import MDeparts from "../../components/m/MDeparts";
export default {
  name: "Kampo",
  components: {MDeparts, MFooter, MTop, DepartmentAside, PageFooter, PageHeader}
}
</script>

<style scoped>
/* PC */
.hidden-sm-and-down .img {
  margin-top: 30px;
}
.detail1 {
  margin: 40px 20px;
}
.content1 {
  margin-top: 30px;
}
.content1 .title1 {
  color: #e88383;
  margin-bottom: 10px;
}
.content1 .text1 {
  color: #535353;
  line-height: 1.5;
}
.other {
  color: #535353;
  font-size: 14px;
  line-height: 1.5;
}

/* SP */
.m-main {
  background-color: #FFFFFF;
  border-top: 2px solid #e5d05e;
   border-bottom: 2px solid #e5d05e;
  padding: 2rem 0;
}
.m-title {
  color: #535353;
  font-size: 24px;
  text-align: center;
  margin: 2rem 0;
}
.m-detail {
  background-color: #FFFFFF;
  padding: 0 1rem;
}
.m-subtitle {
  color: #e88383;
  font-size: 18px;
  padding: 2rem 0 1rem;
}
.m-content {
  line-height: 24px;
  color: #535353;
}
</style>
